import { BrowserModule } from '@angular/platform-browser';

import { MDBSpinningPreloader } from 'ng-uikit-pro-standard';

import { NgModule } from '@angular/core';

import { AppComponent } from './app.component';
import { RoutingComponents, AppRoutingModule } from './app.routing';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ToastModule, ToastService } from 'ng-uikit-pro-standard';
import { MDBBootstrapModulesPro } from 'ng-uikit-pro-standard';
import { MainNavbarComponent } from './layouts/main-navbar/main-navbar.component';
import { MainFooterComponent } from './layouts/main-footer/main-footer.component';


@NgModule({
  declarations: [
    AppComponent, 
    RoutingComponents, 
    MainNavbarComponent, 
    MainFooterComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    MDBBootstrapModulesPro.forRoot(),
    ToastModule.forRoot(),
    FormsModule,
    HttpClientModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,
  ],
  providers: [MDBSpinningPreloader, ToastService],
  bootstrap: [AppComponent],
})
export class AppModule {}
