import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators  } from '@angular/forms';
import { HttpClient } from '@angular/common/http';

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.scss']
})
export class ContactComponent implements OnInit {
  contactForm: FormGroup;
  name: string;
  email: string;
  phoneNumber: string;
  company: string;
  subject: string;
  message: string;

  obj: any;

  @ViewChild('frame', {static: false}) frame: any

  baseUrl = document.location.protocol + '//' + document.location.hostname + '/api';

  constructor(private fb: FormBuilder, private http: HttpClient) { 
    this.contactForm = this.fb.group({
      'name': [null, Validators.compose([Validators.required])],
      'email': [null, Validators.required],
      'phoneNumber': [null, Validators.required],
      'company': [null, Validators.required],
      'subject': [null, Validators.required],
      'message': [null, Validators.required],
    })
  }

  ngOnInit() {
    
  }

  send(data) {
    this.name = data.name
    this.email = data.email
    this.phoneNumber = data.phoneNumber
    this.company = data.company
    this.subject = data.subject
    this.message = data.message

    this.obj = {
      name: this.name,
      email: this.email,
      phoneNumber: this.phoneNumber,
      company: this.company,
      subject: this.subject,
      message: this.message
    }

    this.http.post(this.baseUrl + '/email/contact', this.obj).subscribe((res:any) => {
      if(res.message == 'SUCCESS') {
        this.contactForm.reset()
        this.frame.show()
      }
    })

  }

}
