import { Component, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-ifrs9',
  templateUrl: './ifrs9.component.html',
  styleUrls: ['./ifrs9.component.scss']
})
export class Ifrs9Component implements OnInit {
  videoUrl: any
  url = "https://www.youtube.com/embed/dIkGpUJMn1g"

  constructor(private sanitizer: DomSanitizer) {
    this.videoUrl = this.sanitizer.bypassSecurityTrustResourceUrl(this.url)
  }

  ngOnInit() {
    
  }

  onClick() {
    
  }

}
