import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {
  isRoot: boolean
  activeClass: string
  constructor() { }

  ngOnInit() {
    this.isRoot = location.pathname == "/"
    this.activeClass = ""
    console.log(this.isRoot)
  }

}
