import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomeComponent } from './pages/home/home.component';
import { AboutComponent } from './pages/about/about.component';
import { ServicesComponent } from './pages/services/services.component';
import { ContactComponent } from './pages/contact/contact.component';
import { Ifrs9Component } from './pages/ifrs9/ifrs9.component';
import { AboutIfrs9Component } from './pages/about-ifrs9/about-ifrs9.component';
import { DocproComponent } from './pages/docpro/docpro.component';
import { AmlproComponent } from './pages/amlpro/amlpro.component';
import { ELenderComponent } from './pages/e-lender/e-lender.component';
import { LoanproComponent } from './pages/loanpro/loanpro.component';
import { TestimonialsComponent } from './pages/testimonials/testimonials.component';
import { PageNotFoundComponent } from './pages/page-not-found/page-not-found.component';
import { ComingSoonComponent } from './pages/coming-soon/coming-soon.component';
import { CollectorProComponent } from './pages/collector-pro/collector-pro.component';

const routes: Routes = [
  {
    path: '',
    component: HomeComponent
  },
  {
    path: 'about',
    component: AboutComponent
  },
  {
    path: 'about-ifrs-9',
    component: AboutIfrs9Component
  },
  {
    path: 'services',
    component: ServicesComponent
  },
  {
    path: 'services/ifrs-9',
    component: Ifrs9Component
  },
  {
    path: 'services/loanpro',
    component: LoanproComponent
  },
  {
    path: 'services/aml-pro',
    component: AmlproComponent
  },
  {
    path: 'services/doc-pro',
    component: DocproComponent
  },
  {
    path: 'services/e-lender',
    component: ELenderComponent
  },
  {
    path: 'services/collector-pro',
    component: CollectorProComponent
  },
  {
    path: 'testimonials',
    component: TestimonialsComponent
  },
  {
    path: 'contact',
    component: ContactComponent
  },
  {
    path: 'coming-soon',
    component: ComingSoonComponent
  },
  {
    path: '**',
    component: PageNotFoundComponent
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {scrollPositionRestoration: 'enabled'})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
export const RoutingComponents = [
  HomeComponent,
  AboutComponent,
  ServicesComponent,
  ContactComponent,
  LoanproComponent,
  Ifrs9Component,
  AboutIfrs9Component, 
  DocproComponent, 
  AmlproComponent, 
  ELenderComponent,
  CollectorProComponent,
  TestimonialsComponent,
  ComingSoonComponent,
  PageNotFoundComponent
];