import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-docpro',
  templateUrl: './docpro.component.html',
  styleUrls: ['./docpro.component.scss']
})
export class DocproComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
