import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-e-lender',
  templateUrl: './e-lender.component.html',
  styleUrls: ['./e-lender.component.scss']
})
export class ELenderComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
